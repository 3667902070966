import React, { useState } from "react";

/** SCSS */
import "./SearchField.scss";

/** MATERIAL DESIGN UI */
import { createStyles, makeStyles, Theme } from "@material-ui/core/styles";
import {
  FormControl,
  IconButton,
  InputBase,
  MenuItem,
  Paper,
  Select
} from "@material-ui/core";
import { Close as CloseIcon, Search as SearchIcon } from "@material-ui/icons";
import InputMask from "react-input-mask";

/** INTERFACES */
interface OwnProps {
  className?: string;
  onCloseSearch: () => void;
  onSearch: (searchValue: string, selectedSearchByValue: string) => void;
}

interface SearchByItem {
  disabled: boolean;
  text: string;
  value: string | 0;
}

/** CREATE STYLES MATERIAL DESIGN UI */
const useStyles = makeStyles((theme: Theme) => {
  return createStyles({
    paper: {
      alignItems: "center",
      display: "flex",
      padding: "0 4px 0 16px",
      width: 'calc(30% - 10px)'
    },
    inputSearch: {
      display: "flex",
      flex: 1
    },
    selectSearchBy: {
      "&:before": {
        display: "none"
      },
      "&:after": {
        display: "none"
      }
    }
  });
});

const SearchField = ({ onCloseSearch, onSearch }: OwnProps) => {
  const classes = useStyles();
  const searchByItems: SearchByItem[] = [
    { text: 'Buscar por', value: 0, disabled: true },
    { text: 'CPF', value: 'cpf', disabled: false },
    { text: 'E-mail', value: 'email', disabled: false },
    { text: 'Nome', value: 'full_name', disabled: false },
  ];

  const searchDefautlValues = {
    searchValue: "",
    searchBy: "cpf",
    searchByText: "CPF"
  }

  const [search, setSearch] = useState(searchDefautlValues);

  const handleOnChangeSearchBy = (event: React.ChangeEvent<{ value: any }>) => {
    const selectedSearchByItem = searchByItems.filter(item => item.value === event.target.value)[0];

    setSearch(s => ({
      ...s,
      searchBy: event.target.value,
      searchByText: selectedSearchByItem.text
    }));
  };

  const handleClose = () => {
    setSearch(searchDefautlValues);
    onCloseSearch();
  }

  const handleSearch = () => {
    onSearch(search.searchValue, search.searchBy);
  };
  
  const renderSearchField = () => {
    const inputBaseProps = {
      className: classes.inputSearch,
      placeholder: `Buscar por ${search.searchByText}`,
      value: search.searchValue,
      onChange: (e: any) => {
        e.persist();
        setSearch(s => ({ ...s, searchValue: e.target.value }));
      },
      onKeyPress: (e: any) => {
        if  (e.which === 13 || e.keyCode === 13) {
          handleSearch()
        }

        return;
      }
    }

    if (search.searchBy === 'cpf') {
      return (
        <InputMask
          mask={"999.999.999-99"}
          {...inputBaseProps}
        >
          {(inputProps: any) => (<InputBase {...inputProps} />)}
        </InputMask>
      )
    } else {
      return (
        <InputBase {...inputBaseProps} />
      )
    }
  }

  return (
    <Paper className={classes.paper}>
      {renderSearchField()}
      <FormControl>
        <Select
          className={classes.selectSearchBy}
          inputProps={{ name: "searchBy" }}
          onChange={handleOnChangeSearchBy}
          value={search.searchBy}
        >
          {searchByItems.map((item) => <MenuItem key={item.value} value={item.value} {...item.disabled}>{item.text}</MenuItem>)}
        </Select>
      </FormControl>
      <IconButton onClick={() => handleSearch()}>
        <SearchIcon />
      </IconButton>
      <IconButton onClick={() => handleClose()}>
        <CloseIcon />
      </IconButton>
    </Paper>
  );
};

export default SearchField;
