import { RejectionReasonsActionTypes as actionTypes, RejectionReason } from './types';

export function getAllRejectionReasons() {
  return {
    type: actionTypes.GET_ALL_REQUEST
  }
}

export function getAllRejectionReasonsSuccess(data: RejectionReason[]) {
  return {
    type: actionTypes.GET_ALL_SUCCESS,
    payload: data
  }
}

export function getAllRejectionReasonsFailure(error: any) {
  return {
    type: actionTypes.GET_ALL_FAILURE,
    payload: error
  }
}