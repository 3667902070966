import { Reducer } from "redux";
import { DocumentsState, DocumentsActionTypes as actionTypes } from "./types";

export const initialState: DocumentsState = {
  loading: false,
  error: false,
  lastStatus: undefined,
  data: [],
  count: 0
};

const reducer: Reducer<DocumentsState> = (
  state = initialState,
  { type, payload }
) => {
  switch (type) {
    case actionTypes.GET_REQUEST:
      return {
        ...state,
        loading: true
      };

    case actionTypes.GET_SUCCESS:
      return {
        ...state,
        loading: false,
        data: payload.docs,
        count: payload.count
      };

    case actionTypes.GET_FAILURE:
      return {
        ...state,
        loading: false,
        error: payload
      };

    case actionTypes.APPROVE_REQUEST:
      return {
        ...state,
        lastStatus: undefined
      };

    case actionTypes.APPROVE_SUCCESS:
      return {
        ...state,
        lastStatus: payload.status
      };

    case actionTypes.APPROVE_FAILURE:
      return {
        ...state,
        error: payload
      };

    case actionTypes.REJECT_REQUEST:
      return {
        ...state,
        lastStatus: undefined
      };

    case actionTypes.REJECT_SUCCESS:
      return {
        ...state,
        lastStatus: payload.status
      };

    case actionTypes.REJECT_FAILURE:
      return {
        ...state,
        error: payload
      };

    default:
      return state;
  }
};

export { reducer as documentsReducer };
