import React from 'react';
import { connect } from "react-redux";

/** MATERIAL DESIGN UI */
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';
import { Badge, Drawer, List, ListItem, ListItemIcon, ListItemText } from '@material-ui/core';
import { Attachment as IconAttachment } from '@material-ui/icons';

/** APPLICATION STATE */
import { ApplicationState } from "./../../store";

/** TYPES */
import { DocumentsState } from "./../../store/documents/types";

interface StateProps {
  documents: DocumentsState;
}

type Props = StateProps;

const useStyles = makeStyles((theme: Theme) => {
  const drawerWidth: number = 300;

  return createStyles({
    drawer: {
      width: drawerWidth
    },
    drawerPaper: {
      width: drawerWidth
    },
    toolbar: theme.mixins.toolbar,
  })
});

const Sidebar = (props: Props) => {
  const classes = useStyles();
  const drawerItems = [
    {
      icon: <IconAttachment />,
      text: 'Documentos Pendentes',
      count: props.documents.count
    }
  ];

  return (
    <Drawer
      className={classes.drawer}
      variant={"permanent"}
      classes={{ paper: classes.drawerPaper }}
    >
      <div className={classes.toolbar} />
      <List>
        {drawerItems.map((drawerItem, index) => (
          <ListItem button key={index}>
            <ListItemIcon>
              <Badge badgeContent={drawerItem.count} color={'primary'}>
                {drawerItem.icon}
              </Badge>
            </ListItemIcon>
            <ListItemText primary={drawerItem.text} />
          </ListItem>
        ))}
      </List>
    </Drawer>
  );
};

const mapStateToProps = (state: ApplicationState) => ({
  documents: state.documents
});

export default connect(mapStateToProps)(Sidebar);
