export enum RejectionReasonsActionTypes {
  GET_ALL_REQUEST = "@rejectionReasons/GET_ALL_REQUEST",
  GET_ALL_SUCCESS = "@rejectionReasons/GET_ALL_SUCCESS",
  GET_ALL_FAILURE = "@rejectionReasons/GET_ALL_FAILURE",
}

export interface RejectionReason {
  id: number
  title: string
  description: string
}

export interface RejectionReasonsState {
  readonly loading: boolean
  readonly error: boolean
  readonly data: RejectionReason[]
}