import { DocumentsActionTypes as actionTypes, Document, GetDocuments } from './types';

export function getDocuments(params?: GetDocuments) {
  return {
    type: actionTypes.GET_REQUEST,
    payload: params
  }
}

export function getDocumentsSuccess(data: Document[]) {
  return {
    type: actionTypes.GET_SUCCESS,
    payload: data
  }
}

export function getDocumentsFailure(error: any) {
  return {
    type: actionTypes.GET_FAILURE,
    payload: error
  }
}

export function approveDocument(doc_id: number) {
  return {
    type: actionTypes.APPROVE_REQUEST,
    payload: { doc_id }
  }
}

export function approveDocumentSuccess(data: any) {
  return {
    type: actionTypes.APPROVE_SUCCESS,
    payload: data
  }
}

export function approveDocumentFailure(error: any) {
  return {
    type: actionTypes.APPROVE_FAILURE,
    payload: error
  }
}

export function rejectDocument(doc_id: number, rejection_reason_ids: number[]) {
  return {
    type: actionTypes.REJECT_REQUEST,
    payload: { doc_id, rejection_reason_ids }
  }
}

export function rejectDocumentSuccess(data: any) {
  return {
    type: actionTypes.REJECT_SUCCESS,
    payload: data
  }
}

export function rejectDocumentFailure(error: any) {
  return {
    type: actionTypes.REJECT_FAILURE,
    payload: error
  }
}