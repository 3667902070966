import React, { FunctionComponent } from 'react';

/** MATERIAL DESIGN UI */
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';
import { CssBaseline } from '@material-ui/core';

/** COMPONENTS */
import Header from './components/header';
import Sidebar from './components/sidebar';
import Content from './components/content';
import DocumentsList from './components/documentsList';

/** CREATE STYLES MATERIAL DESIGN UI */
const useStyles = makeStyles((theme: Theme) => {
  return createStyles({
    root: {
      display: 'flex'
    },
    main: {
      flexGrow: 1,
      padding: theme.spacing(3)
    },
    toolbar: theme.mixins.toolbar,
  })
});

const App: FunctionComponent = () => {
  const classes = useStyles();

  return (
    <div className={classes.root}>
      <CssBaseline />
      <Header />
      <Sidebar />
      <main className={classes.main}>
        <div className={classes.toolbar} />
        <Content>
          <DocumentsList />
        </Content>
      </main>
    </div>
  )
}

export default App;
