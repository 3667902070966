export enum DocumentsActionTypes {
  GET_REQUEST = "@documents/GET_REQUEST",
  GET_SUCCESS = "@documents/GET_SUCCESS",
  GET_FAILURE = "@documents/GET_FAILURE",
  APPROVE_REQUEST = "@documents/APPROVE_REQUEST",
  APPROVE_SUCCESS = "@documents/APPROVE_SUCCESS",
  APPROVE_FAILURE = "@documents/APPROVE_FAILURE",
  REJECT_REQUEST = "@documents/REJECT_REQUEST",
  REJECT_SUCCESS = "@documents/REJECT_SUCCESS",
  REJECT_FAILURE = "@documents/REJECT_FAILURE",
}

export interface Document {
  doc_id: string
  doc_type: string
  doc_url: string
  doc_status: string
  doc_rejection_reasons: string[]
  user_id: string
  user_name: string
  user_email: string
  user_cpf: string
}

export interface PaginationParams {
  limit?: number
  offset?: number 
}

export interface SearchParams {
  searchBy?: string
  searchValue?: string
}

export type GetDocuments = PaginationParams & SearchParams;

export interface DocumentsState {
  readonly loading: boolean
  readonly error: any
  readonly lastStatus: string | undefined
  readonly data: Document[]
  readonly count: number
}
