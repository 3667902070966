import React from 'react';
import { createStyles, makeStyles, Theme } from "@material-ui/core/styles";
import { Button, Chip, TableCell, TableRow } from "@material-ui/core";

/** INTERFACES */
import { Document } from './../../store/documents/types';

const useStyles = makeStyles((theme: Theme) => {
  return createStyles({
    button: {
      margin: theme.spacing(1)
    },
    divider: {
      marginTop: theme.spacing(3),
      marginBottom: theme.spacing(3)
    },
    listRejectionItem: {
      paddingLeft: 0,
    }
  });
});

interface OwnProps {
  onApproveDocument: () => void;
  document: Document;
  onOpenImageModal: (open: boolean) => void;
  onClickRejectDocument: () => void;
}

const DocumentsItem = (props: OwnProps) => {
  const classes = useStyles();
  const { document, onApproveDocument, onOpenImageModal, onClickRejectDocument } = props;
  const dictionary: { [x: string]: string } = {
    'rejected': 'Rejeitado',
    'validated': 'Aprovado'
  };

  const _renderCustomActionCell = () => (
    <>
      <Button
        className={classes.button}
        variant={"contained"}
        color={"primary"}
        onClick={() => onApproveDocument()}
      >
        Aprovar
      </Button>
      <Button
        className={classes.button}
        variant={"contained"}
        color={"secondary"}
        onClick={() => onClickRejectDocument()}
      >
        Rejeitar
      </Button>
    </>
  );

  const _renderCustomDescriptionCell = (document: Document) => (
    <>
      <div>
        <strong>Status:</strong> {_translateStatus(document.doc_status)}
      </div>
      {document.doc_rejection_reasons.map(reason => (
        <>
          <br/>
          <div>{reason}</div>
        </>
      ))}
    </>
  );

  const _translateStatus = (status: string): string => {
    return dictionary[status];
  }

  return (
    <TableRow key={document.doc_id}>
      <TableCell>{document.doc_id}</TableCell>
      <TableCell>
        {document.user_name}
        <br />
        {document.user_email}
        <br />
        {document.user_cpf}
        <br />
      </TableCell>
      <TableCell>
        <Button
          className={classes.button}
          variant="contained"
          color="primary"
          onClick={() => onOpenImageModal(true)}
        >
          Abrir Documento
      </Button>
        <Button
          className={classes.button}
          variant="outlined"
          color="primary"
          onClick={() => window.open(document.doc_url)}
        >
          Fazer download
      </Button>
      </TableCell>
      <TableCell>
        <Chip label={document.doc_type} variant={"outlined"} />
      </TableCell>
      <TableCell>
        {
          document.doc_status === "awaiting_validation"
            ? _renderCustomActionCell()
            : _renderCustomDescriptionCell(document)
        }
      </TableCell>
    </TableRow>
  )
};

export default DocumentsItem;
