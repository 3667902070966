import React from 'react';
import { Button, ButtonGroup } from "@material-ui/core";

import './Pagination.scss';

interface OwnProps {
  count: number
  limit: number
  onClick: (offset: number) => void
}

const Pagination = ({ count, limit, onClick }: OwnProps)  => {
  let _numberOfPages: number = 1;

  if (count % limit! > 0) {
    _numberOfPages = Math.floor(count / limit!) + 1;
  } else if(Math.floor(count / limit!) > 0) {
    _numberOfPages = Math.floor(count / limit!);
  }

  const getOffset = (item: number) => {
    return item * limit;
  }

  if (_numberOfPages > 1) {
    _numberOfPages = _numberOfPages > 20 ? 20 : _numberOfPages;
    const numberOfPagesArr = Array.from(Array(_numberOfPages).keys())
    
    return (
      <div className="Pagination">
        <ButtonGroup>
          {numberOfPagesArr.map(item => <Button key={item} onClick={() => onClick(getOffset(item))}>{item + 1}</Button>)}
        </ButtonGroup>
      </div>
    ) 
  }

  return null
}

export default Pagination;