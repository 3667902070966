import React from "react";
import clsx from 'clsx';

/** MATERIAL DESIGN UI */
import { createStyles, makeStyles, Theme } from "@material-ui/core/styles";
import { IconButton, Snackbar, SnackbarContent } from "@material-ui/core";
import {
  CheckCircle as IconSuccess,
  Close as IconClose,
  Error as IconError
} from "@material-ui/icons";
import { green } from "@material-ui/core/colors";

interface Props {
  open: boolean;
  message: string | undefined;
  onClose?: () => any;
  variant: 'success' | 'failure'
}

/** CREATE STYLES MATERIAL DESIGN UI */
const useStyles = makeStyles((theme: Theme) => {
  return createStyles({
    message: {
      display: "flex",
      alignItems: "center"
    },
    icon: {
      fontSize: 20
    },
    iconVariant: {
      opacity: 0.9,
      marginRight: theme.spacing(1)
    },
    success: {
      backgroundColor: green[600]
    },
    failure: {
      backgroundColor: theme.palette.error.dark
    }
  });
});

const Notification = (props: Props) => {
  const classes = useStyles();

  const renderIcon = () => {
    if (props.variant === 'success') {
      return <IconSuccess className={clsx(classes.icon, classes.iconVariant)} />
    } else if (props.variant === 'failure') {
      return <IconError className={clsx(classes.icon, classes.iconVariant)} />
    }
  }

  return (
    <Snackbar
      anchorOrigin={{
        vertical: "top",
        horizontal: "center"
      }}
      autoHideDuration={3000}
      open={props.open}
      onClose={() => (props.onClose ? props.onClose() : undefined)}
    >
      <SnackbarContent
        className={classes[props.variant]}
        message={
          <span className={classes.message}>
            {renderIcon()}
            {props.message}
          </span>
        }
        action={[
          <IconButton key={'icon'} color="inherit" onClick={() => (props.onClose ? props.onClose() : undefined)}>
            <IconClose className={classes.icon} />
          </IconButton>
        ]}
      />
    </Snackbar>
  );
};

export default Notification;
