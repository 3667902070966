import { all, call, fork, put, takeEvery } from 'redux-saga/effects';
import api from './../../services/api';

/** DOCUMENTS ACTIONS AND ACTION TYPES */
import * as actions from './actions';
import { RejectionReasonsActionTypes as actionTypes } from './types';

function* handleGetAllRejectionReasons() {
  try {
    const response = yield call(api.get, '/user/doc/validation/rejection_reasons');

    if (response.error) {
      yield put(actions.getAllRejectionReasonsFailure(response.error))
    } else {
      yield put(actions.getAllRejectionReasonsSuccess(response.data))
    }
  } catch (err) {
    if (err instanceof Error && err.stack) {
      yield put(actions.getAllRejectionReasonsFailure(err.stack))
    } else {
      yield put(actions.getAllRejectionReasonsFailure('An unknown error occured.'))
    }
  }
}

/** WATCHERS */
function* watchGetAllRequest() {
  yield takeEvery(actionTypes.GET_ALL_REQUEST, handleGetAllRejectionReasons);
}

export default function* rejectionReasonsSaga() {
  yield all([
    fork(watchGetAllRequest)
  ])
}