import { Reducer } from 'redux';
import { RejectionReasonsActionTypes as actionTypes, RejectionReasonsState } from './types';

export const initialState: RejectionReasonsState = {
  loading: false,
  error: false,
  data: [],
};

const reducer: Reducer<RejectionReasonsState> = (
  state = initialState,
  { type, payload }
) => {
  switch (type) {
    case actionTypes.GET_ALL_REQUEST:
      return {
        ...state,
        loading: true
      };

    case actionTypes.GET_ALL_SUCCESS:
      return {
        ...state,
        loading: false,
        data: payload
      };

    case actionTypes.GET_ALL_FAILURE:
      return {
        ...state,
        loading: false,
        error: payload
      };

    default:
      return state;
  }
};

export { reducer as rejectionReasonsReducer };
