import React, { Fragment, useCallback, useEffect, useState } from "react";
import { connect } from "react-redux";

/** SCSS */
import './DocumentsList.scss';

/** MATERIAL DESIGN UI */
import { createStyles, makeStyles, Theme } from "@material-ui/core/styles";
import {
  Divider,
  Modal,
  Paper,
  Table,
  TableHead,
  TableRow,
  TableBody,
  TableCell,
  Typography
} from "@material-ui/core";

/** APPLICATION STATE */
import { ApplicationState } from "./../../store";

/** TYPES */
import { DocumentsState, GetDocuments, PaginationParams } from "./../../store/documents/types";

/** ACTIONS */
import { approveDocument, getDocuments } from "./../../store/documents/actions";


/** COMPONENTS */
import DocumentsItem from './../documentsItem';
import Notification from './../notification';
import RejectionReasonsDialog from "../rejectionReasonsDialog";
import SearchField from "../searchField";
import Pagination from "../pagination";

/** ENUMS */
enum ValidationMessages {
  validated = 'Documento validado com sucesso!',
  errorValidated = 'Erro ao validar documento!',
  rejected = 'Documento rejeitado com sucesso!',
  errorRejected = 'Erro ao rejeitar documento!',
  reasonNotSelected = 'Você deve selecionar um motivo de rejeição.',
  genericError = 'Ocorreu um erro. Por favor tente novamente'
}

/** INTERFACES */
interface StateProps {
  documents: DocumentsState;
}

interface DispatchProps {
  approveDocument: (doc_id: number) => any
  getDocuments: (params?: GetDocuments) => void;
}

interface Notification {
  message: string | undefined
  open: boolean
  type: 'success' | 'failure' | undefined
}

type Props = StateProps & DispatchProps;
/** END INTERFACES */

/** CREATE STYLES MATERIAL DESIGN UI */
const useStyles = makeStyles((theme: Theme) => {
  return createStyles({
    button: {
      margin: theme.spacing(1)
    },
    divider: {
      marginTop: theme.spacing(3),
      marginBottom: theme.spacing(3)
    },
    listRejectionItem: {
      paddingLeft: 0,
    },
    title: {
      width: 'calc(70% - 10px)'
    }
  });
});

const DocumentsList = (props: Props) => {
  const classes = useStyles();
  const { approveDocument, documents, getDocuments } = props;
  const [clearReasonsIds, setClearReasonsIds] = useState<boolean>(false)
  const [currentDocument, setCurrentDocument] = useState<string | undefined>(undefined);
  const [isImage, setIsImage] = useState<boolean>(false);
  const [notification, setNotification] = useState<Notification>({ message: undefined, open: false, type: undefined })
  const [openedDocumentId, setOpenedDocumentId] = useState<number>(0);
  const [openImageModal, setOpenImageModal] = useState<boolean>(false);
  const [openRejectionReasonsDialog, setOpenRejectionReasonsDialog] = useState<boolean>(false);
  const [paginationParams, setPaginationParams] = useState<PaginationParams>({ limit: 8, offset: 0 });
  const [searching, setSearching] = useState<boolean>(false)

  useEffect(() => {
      getDocuments(paginationParams);
      // eslint-disable-next-line
  }, [])

  useEffect(() => {
    if (currentDocument) {
      let _img = new Image();
      _img.src = currentDocument;
  
      _img.onload = () => {
        setIsImage(true);
      }
  
      _img.onerror = () => {
        setIsImage(false);
      }
    }
  }, [currentDocument])

  useEffect(() => {
    if (documents && documents.lastStatus) {
      showNotification(documents.lastStatus);
      setClearReasonsIds(true);

      getDocuments(paginationParams);
    }
    //eslint-disable-next-line
  }, [documents.lastStatus])

  useEffect(() => {
    getDocuments(paginationParams)
    //eslint-disable-next-line
  }, [paginationParams])

  const showNotification = (status: string) => {
    let _message = '';

    switch(status) {
      case 'validated':
        _message = ValidationMessages.validated;
        break;

      case 'rejected':
        _message = ValidationMessages.rejected;
        setOpenRejectionReasonsDialog(false);
        break;

      default:
        _message = ValidationMessages.genericError;
        setOpenRejectionReasonsDialog(false);
        break
    }

    setNotification({
      message: _message,
      open: true,
      type: status ? 'success' : 'failure'
    });
  }

  /** CALLBACKS */
  const callbackOpenImageModal = useCallback((open, doc_url = undefined) => {
    setOpenImageModal(open)

    if (open) {
      setCurrentDocument(doc_url)
    } else {
      setCurrentDocument(undefined);
    }
  }, [])

  const callbackOnClickRejectDocument = useCallback((docId: number) => {
    setOpenedDocumentId(docId)
    setOpenRejectionReasonsDialog(true)
  }, [])

  return (
    <Fragment>
      <div className="DocumentsList__header">
        <Typography className={classes.title} variant={"h5"} component={"h2"} noWrap>
          Documentos Pendentes ({documents.count})
        </Typography>
        <SearchField
          onCloseSearch={() => {
            getDocuments(paginationParams);
            setSearching(false);
          }}
          onSearch={(searchValue, searchBy) => {
            getDocuments({ searchBy, searchValue });
            setSearching(true);
          }}
        />
      </div>
      <Divider className={classes.divider} />
      <Paper>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell>ID</TableCell>
              <TableCell>Aluno</TableCell>
              <TableCell>Imagem do Documento</TableCell>
              <TableCell>Tipo do Documento</TableCell>
              <TableCell>Ações</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {documents.data && documents.data.map(document => (
              <DocumentsItem
                document={document}
                key={document.doc_id}
                onApproveDocument={() => approveDocument(Number(document.doc_id))}
                onOpenImageModal={(open) => callbackOpenImageModal(open, document.doc_url)}
                onClickRejectDocument={() => callbackOnClickRejectDocument(Number(document.doc_id))}
              />
            ))}
          </TableBody>
        </Table>
        { !searching
          ? (
              <Pagination
                count={documents.count}
                limit={paginationParams.limit!}
                onClick={(offset) => setPaginationParams(p => ({ ...p, offset: offset }))}
              />
          )
          : (null)
        }
      </Paper>
      <Modal open={openImageModal} onClose={() => callbackOpenImageModal(false)}>
        <div className={'DocumentsList__modal'}>
          {isImage
            ? <img src={currentDocument} alt={'Visualizar Documento'} />
            : <embed src={currentDocument} width={'800px'} height={'800px'} type={'application/pdf'} />
          }
        </div>
      </Modal>
      <RejectionReasonsDialog
        clearReasonsIds={clearReasonsIds}
        onCloseDialog={() => { setOpenRejectionReasonsDialog(false); setClearReasonsIds(true); }}
        open={openRejectionReasonsDialog}
        openedDocumentId={openedDocumentId}
      />
      <Notification
        open={notification.open}
        message={notification!.message}
        variant={notification!.type!}
        onClose={() => setNotification(n => ({...n, open: false}))}
      />
    </Fragment>
  );
};

export default connect(
  (state: ApplicationState) => ({
    documents: state.documents,
  }),
  { approveDocument, getDocuments }
)(DocumentsList);