import { all, fork } from 'redux-saga/effects';

import documentsSaga from './documents/sagas';
import rejectionReasons from './rejectionReasons/sagas';

export function* rootSaga() {
  yield all([
    fork(documentsSaga),
    fork(rejectionReasons)
  ])
}