import React, { useEffect, useState } from "react";
import { connect } from "react-redux";

/** MATERIAL DESIGN UI */
import { createStyles, makeStyles, Theme } from "@material-ui/core/styles";
import {
  Button,
  Checkbox,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
} from "@material-ui/core";

/** APPLICATION STATE */
import { ApplicationState } from "./../../store";

/** TYPES */
import { RejectionReasonsState } from "./../../store/rejectionReasons/types";

/** ACTIONS */
import { getAllRejectionReasons } from "./../../store/rejectionReasons/actions";
import { rejectDocument } from './../../store/documents/actions';

/** INTERFACES */
interface OwnProps {
  clearReasonsIds: boolean;
  onCloseDialog(): void;
  open: boolean;
  openedDocumentId: number;
  onRejectDocument?(id: number, reasons: number[]): void;
}

interface StateProps {
  rejectionReasons: RejectionReasonsState;
}

interface DispatchProps {
  getAllRejectionReasons(): void;
  rejectDocument: (doc_id: number, rejection_reason_ids: number[]) => any;
}

type Props = OwnProps & StateProps & DispatchProps;

/** CREATE STYLES MATERIAL DESIGN UI */
const useStyles = makeStyles((theme: Theme) => {
  return createStyles({
    listRejectionItem: {
      paddingLeft: 0,
    }
  });
});

const RejectionReasonsDialog = (props: Props) => {
  const classes = useStyles();
  const { onCloseDialog, open, openedDocumentId, rejectDocument, rejectionReasons } = props;
  const [selectedReasonsIds, setSelectedReasonsIds] = useState<number[]>([])

  useEffect(() => {
    const { getAllRejectionReasons } = props

    getAllRejectionReasons();
    // eslint-disable-next-line
  }, [])

  useEffect(() => {
    if (props.clearReasonsIds) {
      setSelectedReasonsIds([])
    }
  }, [props.clearReasonsIds])

  const handleOnChangeCheckbox = (id: number) => {
    if (selectedReasonsIds.includes(id)) {
      setSelectedReasonsIds(s => s.filter(item => item !== id))
    } else {
      setSelectedReasonsIds(s => [...s, id])
    }
  }

  return (
    <Dialog open={open}>
      <DialogTitle>Selecione o motivo da rejeição</DialogTitle>
      <DialogContent dividers>
        <List>
          {rejectionReasons && rejectionReasons.data.map((item, index) => {
            const itemId:number = Number(item.id);

            return (
              <ListItem className={classes.listRejectionItem} key={index}>
                <ListItemIcon>
                  <Checkbox
                    checked={selectedReasonsIds.includes(itemId)}
                    value={itemId}
                    color="primary"
                    onChange={() => handleOnChangeCheckbox(itemId)}
                  />
                </ListItemIcon>
                <ListItemText primary={item.title}/>
              </ListItem>
            )
          })}
        </List>
      </DialogContent>
      <DialogActions>
        <Button color="primary" onClick={() => onCloseDialog()}>Cancelar</Button>
        <Button color="primary" onClick={() => rejectDocument(openedDocumentId, selectedReasonsIds)}>Rejeitar documento</Button>
      </DialogActions>
    </Dialog>
  );
};

export default connect(
  (state: ApplicationState) => ({
    rejectionReasons: state.rejectionReasons
  }),
  { getAllRejectionReasons, rejectDocument }
)(RejectionReasonsDialog);