import { all, call, fork, put, takeEvery } from 'redux-saga/effects';
import api from './../../services/api';

/** DOCUMENTS ACTIONS AND ACTION TYPES */
import * as actions from './actions';
import { DocumentsActionTypes as actionTypes } from './types';

function* handleGetDocuments({ payload }: any) {
  try {
    let _response = null;

    if (payload && payload.searchBy && payload.searchValue) {
      _response = yield call(api.get, `/user/doc/validation/search?fieldName=${payload.searchBy}&fieldValue=${payload.searchValue}`)
    } else {
      let _queryParams = '';

      if (payload && payload.offset !== undefined && payload.limit) {
        _queryParams = `?offset=${payload.offset}&limit=${payload.limit}`;
      }

      _response = yield call(api.get, `/user/doc/validation/all${_queryParams}`);
    }

    if (_response.error) {
      yield put(actions.getDocumentsFailure(_response.error))
    } else {
      yield put(actions.getDocumentsSuccess(_response.data))
    }
  } catch (err) {
    if (err instanceof Error && err.stack) {
      yield put(actions.getDocumentsFailure(err.stack))
    } else {
      yield put(actions.getDocumentsFailure('An unknown error occured.'))
    }
  }
}

function* handleApproveDocument({ payload }: any) {
  try {
    const response = yield call(api.put, `/user/doc/validation/${payload.doc_id}/accept`);

    if (response.error) {
      yield put(actions.approveDocumentFailure(response.error))
    } else {
      yield put(actions.approveDocumentSuccess(response.data))
    }
  } catch (err) {
    if (err instanceof Error && err.stack) {
      yield put(actions.approveDocumentFailure(err.stack))
    } else {
      yield put(actions.approveDocumentFailure('An unknown error occured.'))
    }
  }
}

function* handleRejectDocument({ payload }: any) {
  try {
    const response = yield call(api.put, `/user/doc/validation/${payload.doc_id}/reject`, {
      rejection_reason_ids: payload.rejection_reason_ids
    });

    if (response.error) {
      yield put(actions.rejectDocumentFailure(response.error))
    } else {
      yield put(actions.rejectDocumentSuccess(response.data))
    }
  } catch (err) {
    if (err instanceof Error && err.stack) {
      yield put(actions.rejectDocumentFailure(err.stack))
    } else {
      yield put(actions.rejectDocumentFailure('An unknown error occured.'))
    }
  }
}

/** WATCHERS */
function* watchGetAllRequest() {
  yield takeEvery(actionTypes.GET_REQUEST, handleGetDocuments);
}

function* watchApproveRequest() {
  yield takeEvery(actionTypes.APPROVE_REQUEST, handleApproveDocument);
}

function* watchRejectRequest() {
  yield takeEvery(actionTypes.REJECT_REQUEST, handleRejectDocument);
}

export default function* documentsSaga() {
  yield all([
    fork(watchApproveRequest),
    fork(watchGetAllRequest),
    fork(watchRejectRequest)
  ])
}