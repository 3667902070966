import React, { FunctionComponent } from 'react';

/** MATERIAL DESIGN UI */
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';
import { AppBar, Toolbar, Typography } from '@material-ui/core';

const useStyles = makeStyles((theme: Theme) => {
  return createStyles({
    appBar: {
      zIndex: theme.zIndex.drawer + 1
    },
  })
});

const Header: FunctionComponent = () => {
  const classes = useStyles();

  return (
    <AppBar className={classes.appBar} position={"fixed"}>
      <Toolbar>
        <Typography variant={"h6"} noWrap>Dashboard Carteirinha</Typography>
      </Toolbar>
    </AppBar>
  );
};

export default Header;
